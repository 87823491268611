/* dm-sans-regular - latin-ext_latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-regular.eot?#iefix")
    format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-regular.woff2")
    format("woff2"), /* Super Modern Browsers */
    url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-regular.ttf")
    format("truetype");
}
/* dm-sans-italic - latin-ext_latin */
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-italic.eot?#iefix")
    format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-italic.woff2")
    format("woff2"),
    /* Super Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-italic.woff")
    format("woff"),
    /* Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-italic.ttf")
    format("truetype");
}
/* dm-sans-500italic - latin-ext_latin */
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 500;
  src: url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500italic.eot?#iefix")
    format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500italic.woff2")
    format("woff2"), /* Super Modern Browsers */
    url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500italic.woff") format("woff"),
    /* Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500italic.ttf")
    format("truetype");
}
/* dm-sans-500 - latin-ext_latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500.eot?#iefix")
    format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500.woff")
    format("woff"),
    /* Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-500.ttf")
    format("truetype");
}
/* dm-sans-700 - latin-ext_latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700.eot?#iefix")
    format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700.woff")
    format("woff"),
    /* Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700.ttf")
    format("truetype");
}
/* dm-sans-700italic - latin-ext_latin */
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700italic.eot?#iefix")
    format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700italic.woff2")
    format("woff2"), /* Super Modern Browsers */
    url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("/public/fonts/dm-sans-v10-latin-ext_latin/dm-sans-v10-latin-ext_latin-700italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Fluent UI Replacement Icons";
  font-weight: 400;
  src: url("/public/fonts/fluent-ui-replacement-icons/fluent-ui-replacement-icons.ttf") format("truetype");
}

body {
  margin: 0;
}

.Toastify__toast-theme--light {
  border-radius: 0;
  padding: 0;
  background: none;
}

.Toastify__toast-container {
  z-index: 1000001;
}

.Toastify__toast {
  box-shadow: none;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast [data-icon-name="Dismiss24Icon"] {
  top: 22px;
  left: auto;
  right: 16px;
  fill: #3b3d40;
}

.Toastify__toast [data-icon-name="Dismiss24Icon"] svg {
  width: 20px;
  height: 20px;
}

.Toastify__toast .ErrorCode__container {
  flex-direction: column-reverse;
}

.Toastify__toast .Toast__dismiss__item {
  align-self: flex-start;
}

.Toastify__toast .Toast--error-code .Toast__icon__item {
  align-self: flex-start;
}

/* ONE TRUST OVERRIDES */
#onetrust-consent-sdk {
  font-family: TeamViewerSans, Noto Sans, verdana, sans-serif;
}
#onetrust-banner-sdk {
  font-family: inherit;
  bottom: 0 !important;
  top: auto !important;
  max-width: none !important;
  width: 100% !important;
  left: 0 !important;
  transform: none !important;
  min-height: 160px;
}

#onetrust-banner-sdk #onetrust-policy-text {
  overflow-y: auto;
  border: 0 !important;
  margin-bottom: 24px !important;
}

#onetrust-banner-sdk .wd-cookie-banner_additional-links {
  padding-top: 20px;
  text-align: left !important;
}

#onetrust-banner-sdk .wd-cookie-banner_link--highlighted {
  width: auto !important;
  font-weight: bold;
  margin-top: 0 !important;
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  padding: 0 30px 15px !important;
}

#onetrust-banner-sdk #onetrust-button-group {
  @media (min-width: 681px) {
    display: flex !important;
    flex-wrap: nowrap;
  }
}

#onetrust-banner-sdk #onetrust-button-group .banner-actions-container {
  flex-basis: 100% !important;
  width: 100% !important;
  @media only screen and (min-width: 681px) {
    flex-basis: auto !important;
    width: auto !important;
    margin-right: 2rem !important;
    margin-left: auto;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row-reverse !important;
    flex-grow: 1;
  }
}

#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group .banner-actions-container {
  @media only screen and (min-width: 681px) {
    flex-direction: row !important;
  }
}

#onetrust-banner-sdk .has-reject-all-button .banner-actions-container {
  max-width: unset !important;
}

#onetrust-consent-sdk #onetrust-reject-all-handler,
#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #accept-recommended-btn-handler,
#onetrust-consent-sdk .save-preference-btn-handler,
#onetrust-consent-sdk .onetrust-close-btn-handler {
  box-sizing: border-box;
  padding: 10px 16px !important;
  background-color: #050a52 !important;
  border: 1px solid #050a52 !important;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
  font-size: 14px !important;
  width: 100% !important;
  max-width: none !important;
  width: 100% !important;
  margin: 10px 0 !important;
  @media (min-width: 681px) {
    width: auto !important;
  }
}

#onetrust-banner-sdk #onetrust-reject-all-handler {
  color: #fff;
  min-width: 135px;
  padding: 12px 10px;
  letter-spacing: 0.05em;
  line-height: 1.4;
  font-weight: 600;
  height: auto;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
  @media (min-width: 681px) {
    margin-right: 2rem !important;
  }
}

#onetrust-consent-sdk #onetrust-pc-btn-handler {
  background-color: #fff !important;
  border: 1px solid #050a52 !important;
}

#onetrust-consent-sdk #banner-options {
  display: none;
}

#onetrust-banner-sdk.otCenterRounded {
  border-radius: 0 !important;
}

#onetrust-pc-sdk .ot-cat-item button {
  background-color: transparent !important;
  text-decoration: underline !important;
  font-color: #141414 !important;
}

.ot-link-btn,
#ot-pc-desc,
.ot-category-desc,
.save-preference-btn-handler,
#accept-recommended-btn-handler,
#onetrust-banner-sdk #onetrust-policy-text {
  font-size: 13px !important;
}

.ot-acc-hdr,
.ot-cat-header,
.ot-always-active {
  font-size: 15px !important;
}

#ot-pc-title,
#ot-category-title {
  font-size: 20px !important;
}

#onetrust-banner-sdk #onetrust-policy-title {
  font-size: 24px !important;
}

.ot-acc-hdr,
.ot-cat-header,
.ot-always-active,
#ot-pc-title,
#ot-category-title,
#onetrust-banner-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-pc-btn-handler {
  color: #050a52 !important;
}

.ot-link-btn,
#onetrust-banner-sdk a,
#onetrust-banner-sdk .wd-cookie-banner_link--highlighted {
  color: #141414 !important;
}

#onetrust-banner-sdk .wd-cookie-banner_link--highlighted {
  display: none;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
  background-color: #050a52 !important;
  border-color: #050a52 !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  border-color: #050a52 !important;
}

#onetrust-banner-sdk .ot-sdk-row,
#onetrust-pc-sdk .ot-sdk-row,
#ot-sdk-cookie-policy .ot-sdk-row {
  margin: auto !important;
  max-width: 1400px !important;
}

/* get rid of MS Edge built-in eye that appears on password forms */
::-ms-reveal {
  display: none;
}
